<template>
  <comprehensive>
    <div class="ddmg-mall stock-content">
      <div class="ddmg-mall-banner">
        <img src="./img/Inquiry_top.png" alt="大大买钢" style="width:100%;margin-bottom:14px;" @click="jumpGYS"/>
      </div>

      <div class="initial_div" v-loading="pageLoading">
        <div class="initial_title">
          <div @click="jump(2)">集采专场</div>
          <div class="select">竞价专场</div>
          <a href="https://apprpc.ddmg.com/#/" target="_blank">
            <div class="approve_button" style="">
              <img src="./img/BidEvaluation1.png" style=""/>评标审批
            </div>
          </a>
        </div>


        <div class="stockBid-condition">
          <div class="stock-condition-box"
               v-if="form.cities.length > 0 || form.originPlaces.length > 0 || form.productNames.length > 0">
            <div class="stock-condition-title">
              全部结果
            </div>
            <div class="search_list" v-for="item in form.cities" :key="item">
              <p>交货地： {{ item }}</p>
              <p><img class="search_img" src="https://ddmallimg.ddmg.com/public/assets/img/index/mall/4@3x.png"
                      alt="大大买钢" style="width: 8px;margin:0px 5px" @click="deleteCondition(1,item)"></p>
            </div>
            <div class="search_list" v-for="item in form.originPlaces" :key="item">
              <p>厂家： {{ item }}</p>
              <p><img class="search_img" src="https://ddmallimg.ddmg.com/public/assets/img/index/mall/4@3x.png"
                      alt="大大买钢" style="width: 8px;margin:0px 5px" @click="deleteCondition(2,item)"></p>
            </div>
            <div class="search_list" v-for="item in form.productNames" :key="item">
              <p>品名： {{ item }}</p>
              <p><img class="search_img" src="https://ddmallimg.ddmg.com/public/assets/img/index/mall/4@3x.png"
                      alt="大大买钢" style="width: 8px;margin:0px 5px" @click="deleteCondition(3,item)"></p>
            </div>
            <div
                class="stock-condition-clear"
                style="color:#e73352ff;cursor:pointer;"
                @click="emptyingCondition">清空条件
            </div>
          </div>
          <div class="stock-detailed-list">
            <div class="detailed-list-title">品名</div>
            <div class="detailed-list-Shell" ref="stockHeight1" :style="{ height:stockHeight1 }">
              <div class="search_item"
                   v-for="item in productNameList"
                   :key="item"
                   @click="productNameClick(item)"
                   :style="{'color': form.productNames.find(obj=> obj == item)?'red':''}"
              >{{ item }}
              </div>
            </div>
            <div class="detailed-list-right">
              <div class="detailed-list-img" @click="foldingDowns(stockHeight1,'1')">
                <div :class="{ 'list-img-mored': true,'list-img-mored-up': this.stockHeight1 != '46px' }"></div>
              </div>
            </div>
          </div>
          <div class="stock-detailed-list">
            <div class="detailed-list-title">厂家</div>
            <div class="detailed-list-Shell" ref="stockHeight2" :style="{ height:stockHeight2 }">
              <div class="search_item"
                   v-for="item in originList"
                   :key="item"
                   @click="originNameClick(item)"
                   :style="{'color': form.originPlaces.find(obj=> obj == item)?'red':''}"
              >{{ item }}
              </div>
            </div>
            <div class="detailed-list-right">
              <div class="detailed-list-img" @click="foldingDowns(stockHeight2,'2')">
                <div :class="{ 'list-img-mored': true,'list-img-mored-up': this.stockHeight2 != '46px' }"></div>
              </div>
            </div>
          </div>
          <div class="stock-detailed-list">
            <div class="detailed-list-title">交货地</div>
            <div class="detailed-list-Shell" ref="stockHeight3" :style="{ height:stockHeight3 }">
              <div class="search_item"
                   v-for="item in cityList"
                   :key="item"
                   @click="cityNameClick(item)"
                   :style="{'color': form.cities.find(obj=> obj == item)?'red':''}"
              >{{ item }}
              </div>
            </div>
            <div class="detailed-list-right">
              <div class="detailed-list-img" @click="foldingDowns(stockHeight3,'3')">
                <div :class="{ 'list-img-mored': true,'list-img-mored-up': this.stockHeight3 != '46px' }"></div>
              </div>
            </div>
          </div>
        </div>

        <div class="Inquiry_div_deadline" style="position: relative">
          <div class="deadline_left">
            <div
                :class="form.activityStatusPC == '' ? 'select' : ''"
                @click="getStatus('')"
            >
              全部
            </div>
            <!--            <div-->
            <!--              :class="form.home_status == 1 ? 'select' : ''"-->
            <!--              @click="getStatus(1)"-->
            <!--            >-->
            <!--              竞价预告-->
            <!--            </div>-->
            <div
                :class="form.activityStatusPC == 2 ? 'select' : ''"
                @click="getStatus(2)"
            >
              竞价中
            </div>
            <div
                :class="form.activityStatusPC == 3 ? 'select' : ''"
                @click="getStatus(3)"
            >
              竞价结束
            </div>
          </div>
          <div class="deadline_right">
            <div class="search_box">
              <i
                  class="glyphicon glyphicon-search"
                  style="margin-left: 10px"
              ></i>
              <input
                  type="text"
                  class="form-control"
                  id="bid_search"
                  placeholder="输入品名、材质等关键词"
                  v-model="form.keyword"
                  @change="searchChange"
              />
            </div>
            <div class="deadline_page" style="">
              <img
                  class="prev-page"
                  src="./img/deadline_l.svg"
                  style="margin: 0px 8px"
                  @click="gotoNext(pagination.pages - 1)"
              />
              <span style="color: #ef2147"
              ><span class="current_page">{{
                  pagination.pages
                }}</span></span
              >/<span class="total_pages">{{ pagination.totalPage }}</span>
              <img
                  class="last-page"
                  src="./img/deadline_r.svg"
                  style="margin: 0px 8px"
                  @click="gotoNext(pagination.pages + 1)"
              />
            </div>
          </div>
        </div>

        <div
            class="rightbodycontainer"
            id="ajax-tbody"
            v-for="item in inquiryPriceList"
            :key="item.id"
        >
          <div class="tableBodyDiv">
            <div class="tableBodyId">
              专场编号：{{ item.activityCode }}
            </div>
            <div class="tableBody_Div" style="width:286px;flex-direction:column;justify-content:center">
              <div
                  style="margin-bottom:10px;font-weight:700;color:#333333;font-size:16px;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;word-break: break-all;">
                {{ item.activityName }}
              </div>
              <div
                  style="color:#666666;font-size:14px;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;word-break: break-all;">
                {{ item.productNames }}
              </div>
            </div>
            <div class="tableBody_Div" style="width:273px;align-items: center;">
              <div style="color:#666666;font-size:14px;">
                报价方式:
                <span style="color:#333333;font-size: 14px;" v-if="item.bidMode == 1">一口价</span>
                <span style="color:#333333;font-size: 14px;" v-else>浮动价</span>
                <div style="height:10px;"></div>
                参与方式:<span style="color:#333333;font-size: 14px;">{{ item.joinModeName }}</span>
              </div>
            </div>
            <div class="tableBody_Div" style="width:235px;align-items: center;">
              <div
                  style="color:#666666;font-size:14px;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;word-break: break-all;">
                <span style="width:60px;display:inline-block;text-align: right;">结算方式:</span>
                <span style="color:#333333;font-size:14px;" v-if="item.settlementMethod == '全额垫资'">
                   {{ item.settlementMethod }},垫资期限{{ item.advanceDay }}天
                </span>
                <span style="color:#333333;font-size:14px;" v-else>
                   {{ item.settlementMethod }}
                </span>
                <div style="height: 10px;"></div>
                <span style="width:60px;display:inline-block;text-align: right;">交货地:</span><span
                  style="color:#333333;font-size:14px;">{{ item.deliveryCityName }}</span>
              </div>
            </div>
            <div class="tableBody_Div" style="width:240px;flex-direction:column;justify-content:center">
              <div style="margin-bottom:10px;color:#999999;font-size:16px;font-weight:500"
                   v-if="item.activityStatusPC == 3">竞价结束
              </div>
              <div style="margin-bottom:10px;color:#ef2147;font-size:16px;font-weight:500"
                   v-if="item.activityStatusPC == 2">竞价中
              </div>
              <div style="color:#999999;font-size:14px;" v-if="item.activityStatusPC == 2">
                <span class="jztime_txt">距结束</span>
                <span class="jztime" style="color:#ef2147;margin-left:4px">
                      <count-down
                          style="display: inline-block"
                          :startTime="Date.now()"
                          :endTime="new Date(item.activateDelayedEnd).getTime()"
                          :dayTxt="'天'"
                          :hourTxt="'时'"
                          :minutesTxt="'分'"
                          :secondsTxt="'秒'"
                      >
                   </count-down>
                </span>
              </div>
            </div>
            <div class="tableBody_Div" style="width:150px;align-items: center;padding-left:0px">

              <div v-if="item.activityStatusPC == 2&&userInfo&&userInfo.company_id!=item.companyId&&item.identity==1"
                   @click="getDetails(item.id,'1')"
                   class="tableBody_button go_bu1"
                   style="width:110px;border:1px solid #ef2147;height:36px;border-radius:2px;color:#ffffff;text-align: center;line-height:34px; background-color: #ef2147;">
                立即竞价
              </div>
              <div v-else @click="getDetails(item.id,'2')" class="tableBody_button show_detail">
                查看详情
              </div>
            </div>

          </div>
        </div>

        <div class="stock-paging" v-if="inquiryPriceList.length > 0">
          <el-pagination
              background
              layout="total,prev, pager, next,jumper"
              :total="pagination.total"
              :page-size="10"
              @current-change="gotoNext"
              :current-page="pagination.pages"
          >
          </el-pagination>
        </div>

        <div
            v-if="inquiryPriceList.length == 0"
            class="rightbodycontainer"
        >
          <div class="empty-div">
            <div>
              <div class="empty-span">
                暂无数据，请<span style="color: #256cd9">重新搜索！</span>
              </div>
            </div>
          </div>
        </div>

      </div>


      <div class="resultsPublicity" style="">
        <div class="resultsPublicity_top">
          <img src="./img/resultsPublicity.png"/>
          结果公示
        </div>

        <div class="publicityDiv_TopDiv">
          <div class="publicityDiv">
            <div class="publicityDiv_Top">公示时间</div>
          </div>
          <div class="publicityDiv">
            <div class="publicityDiv_Top">专场名称</div>
          </div>
          <div class="publicityDiv">
            <div class="publicityDiv_Top">结果</div>
          </div>
        </div>

        <div class="resultsPublicity_div" id="slide">
          <scroll
              class="seamless-warp"
              :data="bidActivityWinnerList"
              :class-option="classOption"
          >
          <div id="slide1">
            <div
                class="publicityDiv"
                v-for="(item, index) in bidActivityWinnerList"
                :key="index"
            >
              <div class="publicityDiv_text">
                {{ item.appraiseTime }}
              </div>
              <div class="publicityDiv_text">{{ item.activityName }}</div>
              <div
                  class="publicityDiv_text"
                  v-if="item.status == '中标'"
              >
                {{ item.result }}
                {{ item.status }}
              </div>
              <div class="publicityDiv_text" v-else>
                {{ item.status }}
              </div>
            </div>

<!--            <div id="slide2"></div>-->
          </div>
          </scroll>
        </div>
      </div>
    </div>
    <el-dialog
        title="提示"
        :visible.sync="dialogVisible"
        width="50%"
    >
      <div style="height: 288px;background-color: #ffffff;text-align: center">
        <img src="https://poldwww.ddmg.com/assets/img/newbid/12743.png" style="margin-top: 15px">
        <h4 style="font-size: 14px;color:#333333;text-align: center;margin-top: 30px">
          请先成为大大买钢认证供应商,如有疑问</h4>
        <h4 style="font-size: 14px;color:#FAA44B;text-align: center;margin-top: 10px">请联系客服:400-888-2566</h4>
        <el-button @click="jumpGYS" class="btn"
                   style="width: 115px;height: 32px;background-color: #FF6200;color: #ffffff;border-radius: 0;font-size: 16px;margin-top: 29px;line-height: 6px">
          申请入驻
        </el-button>
      </div>
    </el-dialog>
  </comprehensive>
</template>

<script>
import util from "@/libs/util.js";
import comprehensive from "../../components/comprehensive.vue";
import {mapState, mapActions} from "vuex";
import storage from "@/utils/storage";
import scroll from "vue-seamless-scroll";
import CountDown from "vue2-countdown";

export default {
  name: 'bid',
  components: {
    comprehensive,
    CountDown,
    scroll
  },
  data() {
    return {
      form: {
        pages: 1,                //类型：Number  必有字段  备注：页数
        rows: 10,                //类型：Number  必有字段  备注：每页条数
        activityStatusPC: 0,
        keyword: '',
        productNames: [],     //品名列表
        originPlaces: [],  //产地列表
        cities: ['长沙市'],  //发货地列表
      },
      dialogVisible: false,
      userInfo: {},
      stockHeight1: '46px',
      stockHeight2: '46px',
      stockHeight3: '46px',
      productNameList: [],
      originList: [],
      cityList: [],
    };
  },
  created() {
    if (storage.getStorage("userInfo")) {
      this.userInfo = storage.getStorage("userInfo");
    }
    this.getDataInfo().then(res => {
      this.productNameList = res.productNameList;
      this.originList = res.originList;
      this.cityList = res.cityList;
      this.getInquiryList2(this.form);
    });
    this.getBidActivityWinner();
  },
  methods: {
    ...mapActions("ddmg/bid", ["getInquiryList2", "getBidActivityWinner", "getDataInfo", "getResidentStatus"]),
    deleteCondition(type, item) {
      if (type == 1) {
        this.form.cities.splice(this.form.cities.findIndex(obj => obj == item), 1);
      } else if (type == 2) {
        this.form.originPlaces.splice(this.form.originPlaces.findIndex(obj => obj == item), 1);
      } else {
        this.form.productNames.splice(this.form.productNames.findIndex(obj => obj == item), 1);
      }
      this.getInquiryList2(this.form);
    },
    emptyingCondition() {
      this.form.productNames = [];
      this.form.originPlaces = [];
      this.form.cities = [];
      this.getInquiryList2(this.form);
    },
    productNameClick(item) {
      if (this.form.productNames.find(obj => obj == item)) {
        this.form.productNames.splice(this.form.productNames.findIndex(obj => obj == item), 1);
      } else {
        this.form.productNames.push(item);
      }
      this.getInquiryList2(this.form);
    },
    originNameClick(item) {
      if (this.form.originPlaces.find(obj => obj == item)) {
        this.form.originPlaces.splice(this.form.originPlaces.findIndex(obj => obj == item), 1);
      } else {
        this.form.originPlaces.push(item);
      }
      this.getInquiryList2(this.form);
    },
    cityNameClick(item) {
      if (this.form.cities.find(obj => obj == item)) {
        this.form.cities.splice(this.form.cities.findIndex(obj => obj == item), 1);
      } else {
        this.form.cities.push(item);
      }
      this.getInquiryList2(this.form);
    },
    foldingDowns(type, index) {
      if (index == 1 && type != '46px') {
        this.stockHeight1 = '46px'
      } else if (index == 1 && type == '46px') {
        let childWith = this.$refs.stockHeight1.childNodes;
        let num = 0;
        for (let i of childWith) {
          num += i.offsetWidth + 22.5;
        }
        let height = Math.ceil(num / 980) * 46;
        this.stockHeight1 = height + 'px'
      }
      if (index == 2 && type != '46px') {
        this.stockHeight2 = '46px'
      } else if (index == 2 && type == '46px') {
        let childWith = this.$refs.stockHeight2.childNodes;
        let num = 0;
        for (let i of childWith) {
          num += i.offsetWidth + 22.5;
        }
        let height = Math.ceil(num / 980) * 46;
        this.stockHeight2 = height + 'px'
      }
      if (index == 3 && type != '46px') {
        this.stockHeight3 = '46px'
      } else if (index == 3 && type == '46px') {
        let childWith = this.$refs.stockHeight3.childNodes;
        let num = 0;
        for (let i of childWith) {
          num += i.offsetWidth + 22.5;
        }
        let height = Math.ceil(num / 980) * 46;
        this.stockHeight3 = height + 'px'
      }
    },
    jumpGYS() {
      this.$router.push({name: "supplier"});
    },
    jump(type) {
      if (type == 1) {
        this.$router.push({name: "bid"});
      } else {
        this.$router.push({name: "inquiry"});
      }
    },
    //分页
    gotoNext(val) {
      if (val < 1 || val > this.pagination.total_pages) {
        this.$message.error("当前没有更多页面了");
        return;
      }
      this.form.pages = val;
      this.getInquiryList2(this.form);
    },
    searchChange() {
      this.form.pages = 1;
      this.getInquiryList2(this.form);
    },
    getStatus(type) {
      this.form.activityStatusPC = type;
      this.getInquiryList2(this.form);
    },
    // jump(type) {
    //   if (type == 1) {
    //     this.$router.push({ name: "bid" });
    //   } else {
    //     this.$router.push({ name: "inquiry" });
    //   }
    // },
    //查看详情
    getDetails(id, type) {
      if (type == '1') {
        this.getResidentStatus(this.userInfo.company_id).then((obj) => {

          if (obj.resultdata && obj.resultdata.Status && obj.resultdata.Status == '1') {
            this.$router.push({name: "offerDetails", query: {id: id, type: type}});
          } else {
            this.dialogVisible = true
          }
        });
      } else {
        this.$router.push({name: "offerDetails", query: {id: id, type: type}});
      }

      // let isToken = this.isToken();
      // if(isToken){
      //   this.$router.push({ name: "offerDetails", query: { id: id } });
      // }
    },

  },
  mounted() {

  },
  computed: {
    ...mapState("ddmg/bid", {
      pageLoading: (state) => state.pageLoading,
      bidActivityWinnerList: (state) => state.bidActivityWinnerList,
      inquiryPriceList: (state) => state.inquiryPriceList,
      pagination: (state) => state.pagination2,
    }),
    classOption() {
      return {
        direction: 1,
        hoverStop: true,
        step: 0.2,
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.empty-div {
  background: url(./img/404_03.png) no-repeat center;
  background-size: 80% 30%;
  text-align: center;
  width: 220px;
  height: 320px;
  line-height: 500px;
  margin: 0 auto;
  margin-top: 20px;
  font-size: 14px;
  color: #999;
}

.stock-content .stock-paging {
  display: flex;
  justify-content: center;
  margin-bottom: 60px;
  align-items: center;
  margin-top: 60px;
}

.stock-content .stock-paging .stock-paging-total {
  font-size: 14px;
  color: rgba(140, 140, 140, 1);
  font-weight: 400;
  margin-right: 14.33px;
}

.stock-content .stock-paging .stock-paging-left {
  width: 23.33px;
  height: 23.33px;
  background-color: #fff;
  border-radius: 4px;
  line-height: 23.33px;
  text-align: center;
  margin-right: 14.33px;
}

.stock-content .stock-paging img {
  width: 6px;
  height: 11px;
  background-color: #fff;
  border-radius: 4px;
}

.stock-content .stock-paging .stock-paging-list ul {
  display: flex;
  justify-content: center;
  align-items: center;
}

.stock-content .stock-paging .stock-paging-list li {
  width: 23.33px;
  height: 23.33px;
  background-color: #fff;
  border-radius: 4px;
  line-height: 23.33px;
  text-align: center;
  margin-right: 14.33px;
  font-size: 14px;
  color: rgba(140, 140, 140, 1);
}

.stock-content .stock-paging ul li.active {
  background-color: #f0f0f0;
  color: rgba(38, 38, 38, 1);
}

.stock-content .stock-paging .stock-paging-goto {
  display: flex;
  justify-content: center;
}

.stock-content .stock-paging .stock-paging-right {
  width: 23.33px;
  height: 23.33px;
  background-color: #fff;
  border-radius: 4px;
  line-height: 23.33px;
  text-align: center;
  margin-right: 14.33px;
}

.stock-content .stock-paging .stock-paging-goto {
  font-size: 14px;
  color: rgba(140, 140, 140, 1);
  font-weight: 400;
  display: flex;
  align-items: center;
}

.stock-content .stock-paging .stock-paging-goto p {
  margin-right: 14.33px;
}

.stock-content .stock-paging .stock-paging-goto input {
  width: 44px;
  height: 28px;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid rgba(38, 38, 38, 0.17);
}

.stock-content .stock-paging .stock-paging-goto p:nth-child(3) {
  margin-left: 14.33px;
}

.ddmg-mall {
  width: 100%;
  height: auto;
  background-color: #f3f5f9ff;
}

.ddmg-mall-banner {
  margin: 0 auto;
  width: 1200px;
  cursor: pointer;
}

.initial_div {
  width: 1200px;
  margin: 0px auto;
}

.Inquiry_top_div {
  width: 1200px;
  height: 130px;
  margin: 0px auto;
  margin-bottom: 14px;
  background: url("/assets/img/index/index/Inquiry_top.png") no-repeat center;
  background-size: 100% 100%;
}

.Inquiry_top_div .top_div_left {
  width: 590px;
  margin-right: 10px;
  height: 130px;
  float: left;
}

.Inquiry_top_div .top_div_right {
  width: 590px;
  margin-left: 10px;
  height: 130px;
  float: right;
}

.Inquiry_div {
  width: 1200px;
  padding-bottom: 20px;
  margin: 0 auto;
}

.Inquiry_div_top {
  width: 1195px;
  height: 136px;
  margin: 0 auto;
  background: url(/assets/img/newbid/1263.png) no-repeat center;
}

.stockBid-condition {
  width: 1200px;
  margin: 10px auto;
  background: #ffffff;
  color: #333333ff;
  font-size: 13px;
}

.stockBid-condition .stock-condition-box {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.stockBid-condition .stock-condition-box .stock-condition-title {
  padding-left: 16px;
  font-size: 13px;
  color: #333333ff;
  font-weight: 500;
  width: 74px;
  height: 65px;
  line-height: 65px;
  cursor: pointer;
}

.stockBid-condition .stock-condition-box .stock-condition-clear {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #e73352ff;
  margin-right: 12px;
  max-width: 200px;
  height: 24px;
  border-radius: 2px;
  box-sizing: border-box;
  padding-left: 5px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.stockBid-condition .stock-condition-box .search_list {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #e73352ff;
  margin-right: 12px;
  border: 1px solid #e73352ff;
  max-width: 200px;
  height: 24px;
  border-radius: 2px;
  box-sizing: border-box;
  padding-left: 5px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.stockBid-condition .stock-condition-box .search_list .search_img {
  cursor: pointer;
}

.stockBid-condition .stock-detailed-list {
  display: flex;
  border-bottom: 1px solid #f1f1f1ff;
}

.stockBid-condition .detailed-list-title {
  background-color: #f8f8f8ff;
  padding-left: 16px;
  font-size: 13px;
  color: #333333ff;
  font-weight: 500;
  width: 74px;
  line-height: 46px;
  cursor: pointer;
  caret-color: rgba(0, 0, 0, 0);
}

.stockBid-condition .detailed-list-Shell {
  display: flex;
  width: 980px;
  line-height: 46px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
  flex-wrap: wrap;
  transition: all 1s ease;
}

.stockBid-condition .detailed-list-right {
  display: flex;
  padding-top: 7px;
  margin-left: 30px;
}

.stockBid-condition .detailed-list-right button {
  width: 66px;
  height: 32px;
  line-height: 32px;
  font-size: 14px;
  color: #666666ff;
  border: 1px solid #ddddddff;
  padding-left: 2px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 4px;
  cursor: pointer;
}

.stockBid-condition .detailed-list-right .detailed-list-img {
  margin-top: 8px;
}

.stockBid-condition .detailed-list-right .list-img-mored {
  margin-left: 30px;
  width: 16px;
  height: 16px;
  caret-color: rgba(0, 0, 0, 0);
  cursor: pointer;
  background: url("../../assets/img/downh.svg") no-repeat;
  transition: all 1s ease;
}

.stockBid-condition .detailed-list-right .list-img-mored:hover {
  background: url("../../assets/img/downred.svg") no-repeat !important;
}

.stockBid-condition .detailed-list-right .list-img-mored-up {
  background: url("../../assets/img/uph.svg") no-repeat;
}

.stockBid-condition .detailed-list-right .list-img-mored-up:hover {
  background: url("../../assets/img/upred.svg") no-repeat !important;

}

.search_item {
  color: #333333ff;
  font-size: 13px;
  margin: 0px 11.5px;
  cursor: pointer;
}

.Inquiry_div_deadline {
  width: 100%;
  height: 46px;
  line-height: 46px;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
  align-items: center;
}

.Inquiry_div_deadline .deadline_left {
  height: 46px;
  display: flex;
  align-items: center;
}

.Inquiry_div_deadline .deadline_left div {
  width: 100px;
  height: 37px;
  line-height: 37px;
  color: #333333;
  font-size: 13px;
  text-align: center;
  cursor: pointer;
}

.Inquiry_div_deadline .deadline_left .select {
  color: #ef2147;
  font-size: 13px;
  text-align: center;
}

.Inquiry_div_deadline .deadline_right {
  display: flex;
  align-items: center;
  color: #999999;
}

.Inquiry_div_xiala {
  width: 80px;
  height: 145px;
  background-color: #ffffff;
  position: absolute;
  right: 28px;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.07);
  text-align: center;
  display: none;
}

.Inquiry_div_xiala div {
  width: 80px;
  height: 36px;
}

.Inquiry_div_xiala div:hover {
  background-color: #eeeeee;
  cursor: pointer;
}

.Inquiry_div_zhuti {
  width: 1200px;
  height: 168px;
  background: url(/assets/img/newbid/19.png) no-repeat center;
}

.Inquiry_div_zhuti1 {
  width: 325px;
  height: 160px;
  padding-top: 15px;
  float: left;
  position: relative;
}

.Inquiry_div_zhuti1 span {
  display: inline-block;
  width: 56px;
  line-height: 18px;
  text-align: center;
  padding: 3px 4px;
  border-radius: 2px;
  position: absolute;
  top: 15px;
  left: 0px;
}

.Inquiry_div_zhuti1 div {
  margin-top: 35px;
  width: 100%;
  padding-left: 85px;
}

.Inquiry_div_zhuti2 {
  width: 220px;
  height: 160px;
  float: right;
  padding-top: 15px;
  padding-right: 10px;
  text-align: center;
}

.Inquiry_div_zhuti2 div {
  font-size: 10px;
}

.Inquiry_div_zhuti2 div span {
  width: 26px;
  height: 26px;
  background-color: #f3f6fb;
  color: #dd6665;
  display: inline-block;
  text-align: center;
  line-height: 26px;
  font-size: 14px;
  margin: 0 5px;
}

.Inquiry_data .block {
  display: inline-block;
}

.Inquiry_data .hid {
  display: none;
}

.initial_title {
  width: 1200px;
  height: 84px;
  background-color: #ffffff;
  border-bottom: 1px solid #ef2147;
}

.initial_title div {
  width: 100px;
  height: 84px;
  line-height: 84px;
  float: left;
  margin-left: 80px;
  text-align: center;

  cursor: pointer;
  user-select: none;
}

.initial_title div a {
  font-size: 18px;
  color: #666666;
  cursor: pointer;
  user-select: none;
}

.initial_title .select {
  font-size: 24px;
  color: #ef2147 !important;
  font-weight: bold;
  font-family: "微软雅黑";
}

.Inquiry_top_div .top_div_left {
  width: 590px;
  margin-right: 10px;
  height: 130px;
  float: left;
  background: url("/assets/img/index/index/Inquiry_left.png") no-repeat center;
  background-size: 100% 100%;
}

.Inquiry_top_div .top_div_right {
  width: 590px;
  margin-left: 10px;
  height: 130px;
  float: right;
  background: url("/assets/img/index/index/Inquiry_right.png") no-repeat center;
  background-size: 100% 100%;
}

.approve_button {
  margin-right: 37px;
  font-size: 16px;
  color: #333333;
  display: flex;
  align-items: center;
  float: right !important;

  img {
    margin-right: 10px;
    width: 17px;
    height: 18px;
  }
}

.search_box {
  width: 265px;
  height: 34px;
  border: 1px solid #dddddd;
  display: flex;
  align-items: center;

  input {
    outline: none;
    border: none;
    font-size: 12px;
    color: #555555;
    width: 100%;
    padding: 6px 12px;
  }

  input:focus {
    outline: none;
  }
}

.tableBody_button {
  width: 110px;
  border: 1px solid #ef2147;
  height: 36px;
  border-radius: 2px;
  color: #ef2147;
  text-align: center;
  line-height: 34px;
  cursor: pointer;
  font-size: 12px;
}

.tableBody_button:hover {
  background: #ef2147;
  color: white;
}

.deadline_page {
  margin: 0px 14px;
  display: flex;
  align-items: center;

  img {
    cursor: pointer;
    margin: 0px 8px;
  }
}

.tableBodyDiv {
  padding: 44px 16px 21px;
  height: 52px;
  background-color: #ffffff;
  margin: 10px auto;
  margin-top: 0px;
  position: relative;
  display: flex;
  flex-direction: row;

  .tableBodyId {
    width: 241px;
    height: 25px;
    background-color: #EAECFD;
    border-radius: 0px 0px 16px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    font-family: PingFang SC;
    font-weight: 500;
    color: #3d7dff;
    font-size: 12px;
    line-height: 2px;
  }

  .tableBody_Div {
    float: left;
    display: flex;
  }

}

.resultsPublicity {
  width: 1200px;
  height: 326px;
  border-radius: 6px;
  background-color: #ffffff;
  margin: 0px auto;
}

.resultsPublicity_top {
  padding: 0px 39px;
  height: 60px;
  display: flex;
  align-items: center;
  font-weight: 500;
  color: #ef2147;
  font-size: 16px;
  border-bottom: 1px solid #ef2147;

  img {
    width: 20px;
    height: 20px;
    margin-right: 9px;
    position: relative;
  }
}

.resultsPublicity_div {
  height: 200px;
  overflow: hidden;
  position: relative;
}

.str_move {
  position: absolute;
}

.resultsPublicity_div .publicityDiv {
  display: flex;
  justify-content: space-around;
}

.publicityDiv_TopDiv {
  display: flex;
  justify-content: space-around;
}

.publicityDiv_TopDiv .publicityDiv_Top {
  width: 300px;
  font-weight: 600;
  color: #333333;
  font-size: 14px;
  margin: 16px auto;
  padding-left: 100px;
}

.publicityDiv_text {
  width: 400px;
  color: #666666;
  font-size: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
  margin-bottom: 16px;
  padding-left: 105px;
}
</style>

